.general-data-form-container label {
    margin-bottom: 5px;
    font-weight: bold;
    font-family: var(--font-primary);
    font-size: 1.7rem;
    color: var(--primary-font-color);
    font-weight: 500;
    letter-spacing: 0.05rem;
    text-transform: uppercase;
    margin-bottom: 10px;
}

.general-data-form-container input {
    padding: 10px;
    border-radius: 15px;
    font-family: var(--font-primary);
    border: none;
    background-color: var(--tertiary-background);
    color: var(--primary-font-color);
    width: calc(100% - 120px);
    font-size: 1.2rem;
    box-shadow: 0 2px 12px 5px #0000001a
}