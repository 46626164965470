.form-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  min-height: 100px; /* Adjust as needed */
}

.form-title {
  text-align: center;
  color: var(--primary-font-color);
  font-family: var(--font-primary);
  font-size: 1.8rem;
  letter-spacing: 0.05rem;
  font-weight: 600;
  text-transform: uppercase;
}

.form-title-underline {
  height: 2px;
  background-color: var(--secondary-background);
  width: 80%; /* Adjust width as needed */
  margin-top: 10px; /* Adjust spacing as needed */
}
