.general-data-form-container {
  margin-top: 20px;
  padding: 20px;
  background-color: var(--primary-background);
  border-radius: 15px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  width: 80%;
  min-width: 500px;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

.general-data-form-container .form-title-underline {
    position: relative;

    height: 2px;
    background-color: var(--secondary-background);
    z-index: 0;
    bottom: 20px;
    width: calc(100% - 80px);
    max-width: none !important;
}

.general-data-form-container .form-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 100%;
}

.general-data-form-container .title {
    text-align: center;
    color: var(--primary-font-color);
    font-family: var(--font-primary);
    font-size: 1.8rem;
    letter-spacing: 0.05rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    text-transform: uppercase;

}

.general-data-form-container .create-agent-form {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 100%;
    min-height: 70%;
}

.general-data-form-container .form-group {
    margin-bottom: 15px;
    gap: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    width: 100%;
}



.general-data-form-container button {
    padding: 20px;
    background-color: var(--secondary-background);
    color: var(--primary-font-color);
    border: none;
    border-radius: 15px;
    cursor: pointer;
    font-size: 1.2rem;
    min-width: 150px;
    font-family: var(--font-primary);
    font-weight: 600;
    letter-spacing: 0.05rem;
    box-shadow: 2px 3px 12px 2px #0000001a;
    text-transform: uppercase;
}
