.clients {
  padding: 20px;
  background-color: var(--tertiary-background); 
}

.clients-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
  gap: 50px;
  padding-left: 50px;
  padding-right: 50px;
}

.clients-search-bar {
  margin-bottom: 20px;
}

