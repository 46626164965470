.mission-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.mission-popup-content {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);

  display: flex;
  flex-direction: column;
  gap: 10px;
}

.mission-popup-content h2 {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
}

.mission-popup-content button {
  margin-top: 20px;
}
