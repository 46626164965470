.client-info-details .client-info-details-name {
  font-size: 2rem;
  font-weight: 600;
  color: var(--primary-font-color);
  margin-bottom: 1rem;
  text-align: center;
}

.client-info-details label {
    margin-bottom: 5px;
    font-weight: bold;
    font-family: var(--font-primary);
    font-size: 1.3rem;
    color: var(--primary-font-color);
    font-weight: 700;
    letter-spacing: 0.05rem;
    text-transform: uppercase;
}

.client-info-details .client-info-details-name-underline {
  margin-bottom: 0.5rem;
  top: 0;
  bottom: 10px;
}

.client-info-details .client-info-details-info-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1.5rem;
  width: 90%;
  margin-bottom: 1.5rem;
}

.client-info-details .info-item:only-child,
.client-info-details .info-item:nth-last-child(1):nth-child(odd) {
  grid-column: 1 / -1;
  justify-self: center;
  max-width: 50%;
}

.client-info-details .info-item {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.client-info-details .client-info-details-info {
  font-size: 1.2rem;
  font-family: var(--font-primary);
  font-weight: 500;
  margin-top: 5px;
}

.client-info-details .client-info-details-buttons {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
}

.client-info-details .client-info-details-buttons button {
	width: 100%;
}