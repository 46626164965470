.clients-search-bar {
  display: flex;
  flex-direction: column;
  gap: 10px; /* Space between inputs */
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f9f9f9;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.clients-search-bar input {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  transition: border-color 0.3s;
}

.clients-search-bar input:focus {
  border-color: #007bff; /* Change border color on focus */
}

.clients-search-bar button {
  padding: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.clients-search-bar button:hover {
  background-color: #0056b3; /* Darker blue on hover */
}
