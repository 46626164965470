.edit-popup {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  border-radius: 10px;
  background-color: var(--primary-background);
  max-width: 90%;
  max-height: 90%;
  height: auto;
  width: auto;
  min-width: 80%;
  min-height: 60%;
}

.edit-popup-form {
  gap: 1rem;
  width: 100%;
  padding-left: 1rem;
  padding-right: 1rem;
  margin-bottom: 2rem;
}

.edit-popup-form-container {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  justify-content: space-around;
  width: 100%;
  padding-left: 1rem;
  padding-right: 1rem;
  margin-bottom: 2rem;
}

.edit-popup-form-column {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50%;
  justify-content: center;
}

.edit-popup-label {
  display: block;
  margin-bottom: 8px;
  font-weight: bold;
  font-size: 16px;
  color: var(--primary-font-color);
  margin-bottom: 4px;

}

.edit-popup-input {
  width: 100%;
  padding: 8px;
  margin-bottom: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.edit-popup-buttons {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  justify-content: center;
  align-items: center;
}