.creation-container {
    width: 100%;
    margin: 0 auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    overflow-y: auto;
    height: 100%;
    font-family: var(--font-primary);
}

.creation-container-header {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 100%;
}

.creation-container h1 {
    text-align: center;
    color: #333;
    margin-bottom: 20px;
    font-size: 1.7rem;
}


.create-agent-title {
    font-weight: 700;
    font-size: 1.8rem;
    letter-spacing: 0.1rem;
    text-align: center;
    margin-bottom: 20px;
    color: var(--primary-font-color);
    font-family: var(--font-primary);
    text-transform: uppercase;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
}



.title-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 15rem;
    min-width: fit-content;
}

.create-agent-title {
    margin: 0;
    z-index: 1;
    text-align: center;
}

.create-agent-title-underline {
    position: absolute;
    bottom: 40%;
    height: 2px;
    background-color: var(--secondary-background);
    width: 80%;
    z-index: 0;
}


.creation-container ul {
    list-style-type: none;
    padding: 0;
}

.create-agent-popup-container {
  position: absolute;
  top: 0;
  left: 15vw;
  width: 85vw;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.general-data-form-button {
  position: relative;
  bottom: 10px;
  left: 10px;
  z-index: 1000;
  background-color: var(--secondary-background);
  color: var(--primary-font-color);
  border: none;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  font-size: 2.5rem;
  cursor: pointer;
  transition: transform 0.2s ease-in-out;
}

.no-agents-message {
    text-align: center;
    color: #666;
    font-size: 18px;
    margin-top: 20px;
}

.delete-confirmation-popup {
    background-color: var(--primary-background);
    border: none;
    border-radius: 15px;
    padding: 25px;
    margin: 10px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    box-shadow: 0 2px 10px 10px rgba(0, 0, 0, 0.1);
    min-width: 500px;
    min-height: 300px;
}

.delete-confirmation-popup .delete-confirmation-buttons {
    display: flex;
    gap: 10px;
    min-height: 40px;
}

.delete-confirmation-popup p {
    margin-top: 20px;
    font: var(--font-primary);
    font-size: 1.6rem;
    font-weight: 700;
    letter-spacing: 0.05rem;
    font-family: var(--font-primary);
    text-transform: uppercase;
}

.delete-confirmation-popup .delete-confirmation-buttons button {
    font: var(--font-primary);
    font-size: 1.2rem;
    background-color: var(--secondary-background);
    color: var(--primary-font-color);
    border: none;
    border-radius: 15px;
    padding: 20px;
    min-width: 150px;

    cursor: pointer;
}

