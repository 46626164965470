.popup-container {
  position: fixed;
  top: 0;
  left: 15vw;
  width: 85vw;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 1;
}
