.request-confirm-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  background-color: #00000015;
}

.request-confirm {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #ffffff15;
  border-radius: 10px;
  padding: 20px;
  width: 50%;
  height: 50%;
}

.request-confirm h1 {
  font-size: 24px;
  font-weight: var(--font-primary);
}

.request-confirm h2 {
  font-size: 16px;
  font-weight: var(--font-secondary);
}

.request-confirm-buttons {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
}