.general-data-form-container {
  margin-top: 20px;
  padding: 20px;
  background-color: var(--primary-background);
  border-radius: 15px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  width: 80%;
  min-width: 500px;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

.general-data-form {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.form-group {
  margin-bottom: 15px;
}

.general-data-form label {
  margin-bottom: 5px;
  font-weight: bold;
}

.general-data-form input {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
}

.general-data-form button {
  padding: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}

.general-data-form button:hover {
  background-color: #0056b3;
}
