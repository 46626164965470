.admin-container {
  display: flex;

  height: 100vh;
  width: 100vw;
  z-index: 100;
}

.admin-content {
  height: 100%;
  width: 85%;
  overflow-y: auto;
}