.create-role-container {
  padding: 20px;
  height: 100%;
  overflow-y: auto;
}

.create-role-header {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.create-role-title-container {
  display: flex;
  flex-direction: column;
}

.create-role-title {
  margin: 0;
  font-family: var(--font-primary);
  color: #333;
  font-size: 1.7rem;
  margin-bottom: 20px;
  text-align: center;
}

.create-role-title-underline {
  height: 2px;
  background-color: var(--primary-color);
  width: 100%;
  margin-top: 5px;
}

.create-role-popup-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.delete-confirmation-popup {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
}

.delete-confirmation-buttons {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 15px;
}
