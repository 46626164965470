.navigation-tab {
	height: 100vh;
	width: 15vw;
		
  display: flex;
  flex-direction: column;
  background-color: var(--primary-background);
  color: var(--primary-font-color);
  font-family: var(--font-primary);

  border-right: 1px solid var(--secondary-background);
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.4);
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
  z-index: 1000;
}

.navigation-tab-title {
  width: 100%;
  min-height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.navigation-tab-title p {
  font-size: 1.5rem;
  font-weight: bold;
  color: var(--primary-font-color);
  text-align: center;
  text-transform: uppercase;
  transition: color 0.3s ease;
}

.expandable-button-container {
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 100px);
  overflow-y: auto;
  padding-top: 1rem;
}

.expandable-button-content {
  max-height: 0px;
  height: auto;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: var(--tertiary-font-color) transparent;
  position: relative;
}

.expandable-button-content::-webkit-scrollbar {
  width: 6px;
}

.expandable-button-content::-webkit-scrollbar-track {
  background: transparent;
}

.expandable-button-content::-webkit-scrollbar-thumb {
  background-color: var(--tertiary-font-color);
  border-radius: 25%;
}

.expandable-button {
  width: 100%;
  text-align: center;
  overflow-y: hidden;
  overflow-x: hidden;
}


.expandable-button-content-item {
  color: var(--primary-font-color);
  transition: background-color 0.3s ease;
  font-family: var(--font-primary);

  min-height: 1.5rem;
  font-size: 0.9rem;
  cursor: pointer;
  text-align: left;
  padding-left: 1rem;
  overflow-x: hidden;
}

.expandable-button-content-item:hover {
  background-color: var(--secondary-background);
}

.expandable-button-title {
  font-family: var(--font-accent);
  font-size: 1rem;
  font-weight: bold;
  text-align: left;
  text-transform: uppercase;
  cursor: pointer;
  user-select: none;
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 1rem;
  transition: background-color 0.3s ease;
}

.expandable-button:hover .expandable-button-title {
  background-color: var(--secondary-background);
}

.expandable-button-title-bar {
  width: calc(15vw - 3rem);
  height: 1px;
  background-color: var(--secondary-background);
  padding-left: 1rem;

  position: relative;
  margin-top: auto;

}

.expandable-button-title-up-bar {
  width: calc(15vw - 3rem);
  height: 1px;
  background-color: var(--secondary-background);
  padding-left: 1rem;
  position: relative;
  top: 0;
}