.create-form-container {
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.create-form-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
}

.create-form-title-container {
  flex: 1;
}

.create-form-title {
  font-size: 28px;
  color: #2c3e50;
  margin-bottom: 10px;
}

.create-form-title-underline {
  height: 3px;
  width: 80px;
  background-color: #3498db;
  margin-bottom: 10px;
}

.create-form-container h1 {
  text-align: center;
  color: #333;
  margin-bottom: 20px;
  font-size: 1.7rem;
}

.create-form-title {
  font-weight: 700;
  font-size: 1.8rem;
  letter-spacing: 0.1rem;
  text-align: center;
  margin-bottom: 20px;
  color: var(--primary-font-color);
  font-family: var(--font-primary);
  text-transform: uppercase;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.title-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 15rem;
  min-width: fit-content;
}

.create-form-title {
  margin: 0;
  z-index: 1;
  text-align: center;
}

.create-form-title-underline {
  position: absolute;
  bottom: 40%;
  height: 2px;
  background-color: var(--secondary-background);
  width: 80%;
  z-index: 0;
}

.create-form-container ul {
  list-style-type: none;
  padding: 0;
}

.create-form-popup-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.form-creation-popup {
  background-color: white;
  border-radius: 10px;
  padding: 25px;
  width: 800px;
  max-width: 90%;
  max-height: 90vh;
  overflow-y: auto;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.2);
}

.form-creation-popup h2 {
  font-size: 24px;
  color: #2c3e50;
  margin-bottom: 20px;
  padding-bottom: 10px;
  border-bottom: 2px solid #ecf0f1;
}

.form-section {
  margin-bottom: 30px;
}

.form-section h3 {
  font-size: 18px;
  color: #2c3e50;
  margin-bottom: 15px;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  font-size: 14px;
  color: #7f8c8d;
  margin-bottom: 5px;
}

.form-group input[type="text"],
.form-group input[type="password"],
.form-group select,
.form-group textarea {
  width: 100%;
  padding: 10px 12px;
  border: 1px solid #dcdfe6;
  border-radius: 4px;
  font-size: 14px;
  transition: border-color 0.2s;
}

.form-group input:focus,
.form-group select:focus,
.form-group textarea:focus {
  border-color: #3498db;
  outline: none;
}

.checkbox-group {
  display: flex;
  align-items: center;
}

.checkbox-group label {
  margin-bottom: 0;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.checkbox-group input[type="checkbox"] {
  margin-right: 8px;
}

.fields-list {
  margin-bottom: 20px;
}

.field-item {
  background-color: #f8f9fa;
  border-radius: 6px;
  padding: 15px;
  margin-bottom: 15px;
  border-left: 3px solid #3498db;
}

.field-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.field-header h4 {
  margin: 0;
  font-size: 16px;
  color: #2c3e50;
}

.field-actions {
  display: flex;
  gap: 5px;
}

.move-btn,
.remove-btn {
  padding: 5px 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 12px;
}

.move-btn {
  background-color: #ecf0f1;
  color: #7f8c8d;
}

.move-btn:hover:not(:disabled) {
  background-color: #dcdfe6;
}

.move-btn:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.remove-btn {
  background-color: #e74c3c;
  color: white;
}

.remove-btn:hover {
  background-color: #c0392b;
}

.field-details {
  font-size: 14px;
  color: #7f8c8d;
}

.field-details p {
  margin: 5px 0;
}

.field-options ul {
  margin: 5px 0;
  padding-left: 20px;
}

.add-field-section {
  background-color: #f8f9fa;
  border-radius: 6px;
  padding: 20px;
}

.add-field-section h4 {
  margin-top: 0;
  margin-bottom: 15px;
  font-size: 16px;
  color: #2c3e50;
}

.form-row {
  display: flex;
  gap: 15px;
  margin-bottom: 15px;
}

.form-row .form-group {
  flex: 1;
}

.options-section {
  margin-top: 15px;
  padding: 15px;
  background-color: #ecf0f1;
  border-radius: 4px;
}

.options-section h5 {
  margin-top: 0;
  font-size: 14px;
  color: #2c3e50;
  margin-bottom: 10px;
}

.options-list {
  margin-bottom: 15px;
}

.option-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 10px;
  background-color: white;
  border-radius: 4px;
  margin-bottom: 5px;
}

.remove-option-btn {
  border: none;
  background: none;
  color: #e74c3c;
  cursor: pointer;
  font-size: 16px;
}

.add-option-form {
  margin-top: 10px;
}

.add-option-btn {
  padding: 8px 12px;
  background-color: #3498db;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.add-option-btn:hover {
  background-color: #2980b9;
}

.add-field-btn,
.save-btn,
.cancel-btn {
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 500;
}

.add-field-btn {
  background-color: #3498db;
  color: white;
  margin-top: 15px;
}

.add-field-btn:hover {
  background-color: #2980b9;
}

.form-actions {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 25px;
}

.cancel-btn {
  background-color: #ecf0f1;
  color: #7f8c8d;
}

.cancel-btn:hover {
  background-color: #dcdfe6;
}

.save-btn {
  background-color: #2ecc71;
  color: white;
}

.save-btn:hover:not(:disabled) {
  background-color: #27ae60;
}

.save-btn:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.error-message {
  background-color: #fdecea;
  color: #e74c3c;
  padding: 10px 15px;
  border-radius: 4px;
  margin-bottom: 20px;
  border-left: 3px solid #e74c3c;
}

.no-forms-message {
  text-align: center;
  padding: 40px;
  color: #7f8c8d;
  font-size: 16px;
}

.delete-confirmation-popup {
  background-color: white;
  border-radius: 8px;
  padding: 20px;
  width: 400px;
  text-align: center;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.2);
}

.delete-confirmation-popup p {
  margin-bottom: 20px;
  font-size: 16px;
  color: #2c3e50;
}

.delete-confirmation-buttons {
  display: flex;
  justify-content: center;
  gap: 15px;
}

.delete-confirmation-buttons button {
  padding: 8px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 500;
}

.delete-confirmation-buttons button:first-child {
  background-color: #e74c3c;
  color: white;
}

.delete-confirmation-buttons button:first-child:hover {
  background-color: #c0392b;
}

.delete-confirmation-buttons button:last-child {
  background-color: #ecf0f1;
  color: #7f8c8d;
}

.delete-confirmation-buttons button:last-child:hover {
  background-color: #dcdfe6;
}

.general-data-form-button {
  padding: 8px 15px;
  background-color: #3498db;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 20px;
  transition: background-color 0.2s;
}

.general-data-form-button:hover {
  background-color: #2980b9;
}

.general-data-form-button.close {
  background-color: #e74c3c;

}

.general-data-form-button.close:hover {
  background-color: #c0392b;
}

/* INFO CARD STYLING */
.info-card-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
  margin-top: 20px;
}

.info-card {
  background-color: white;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 0 var(--shadow-spread) rgba(0, 0, 0, 0.1);
  transition: transform 0.2s, box-shadow 0.2s;
  --shadow-spread: 4px;
  display: flex;
  flex-direction: column;
}

.info-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.15);
}

.info-card-name {
  font-size: 18px;
  font-weight: 600;
  color: #2c3e50;
  margin-bottom: 8px;
}

.info-card-name-underline {
  height: 2px;
  width: 50px;
  background-color: #3498db;
  margin-bottom: 15px;
}

.info-card-label {
  font-size: 14px;
  color: #7f8c8d;
  margin-bottom: 5px;
}

.info-card-value {
  font-size: 15px;
  color: #34495e;
  margin-bottom: 15px;
}

.info-card-buttons {
  margin-top: auto;
  display: flex;
  gap: 10px;
}

.info-card-buttons button {
  padding: 8px 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-weight: 500;
  transition: background-color 0.2s;
}

.info-card-buttons button:first-child {
  background-color: #3498db;
  color: white;
}

.info-card-buttons button:first-child:hover {
  background-color: #2980b9;
}

.info-card-buttons button:last-child {
  background-color: #e74c3c;
  color: white;
}

.info-card-buttons button:last-child:hover {
  background-color: #c0392b;
}

