.rdv-card {
  border: 1px solid rgb(83, 83, 83);
  padding: 1rem;
  border-radius: 1rem;
  cursor: pointer;
  background-color: whitesmoke;
  transition: background-color 0.3s;
  height: fit-content;
}

.rdv-card:hover {
  background-color: rgb(235, 235, 235);
}

.selected {
  background-color: rgb(197, 255, 197) !important;
}

@media (max-width: 1200px) and (orientation: portrait) {
  .rdv-card {
    padding: 0.5rem;
  }

  .rdv-card h3 {
    font-size: 1rem;
  }

  .rdv-card p {
    font-size: 0.8rem;
  }
}

@media (max-width: 1200px) and (orientation: landscape) {
  .rdv-card {
    padding: 0.3rem;
  }

  .rdv-card h3 {
    font-size: 0.8rem;
  }

  .rdv-card p {
    font-size: 0.6rem;
  }
}