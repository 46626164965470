.rdv-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  width: 100%;
  flex-wrap: wrap;
  min-height: 50vh;
} 

.button-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.button-wrapper {
  width: 100%;
  gap: 2rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.button-wrapper button {
  background-color: rgb(207, 122, 25);
  color: black;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  text-transform: uppercase;
  height: 4rem;
  cursor: pointer;
}

.cancel-button {
  background-color: rgb(255, 102, 102);
}

.error-message {
  color: rgb(190, 0, 0);
  font-size: 1.5rem;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media (max-width: 1200px) and (orientation: portrait) {
  .button-wrapper {
    gap: 0.5em;
  }

  .button-wrapper button {
    padding: 0.3rem;
    font-size: 0.7rem;
  }

  .rdv-wrapper {
    flex-direction: column;
  }
}

@media (max-width: 1200px) and (orientation: landscape) {
  .button-wrapper {
    gap: 0.5em;
  }

  .button-wrapper button {
    padding: 0.1rem;
    font-size: 0.7rem;
  }

  .rdv-wrapper {
    flex-direction: column;
  }
  
}