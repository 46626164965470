.creation-container {
    width: 100%;
    margin: 0 auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    overflow-y: auto;
    height: 100%;
    font-family: var(--font-primary);
}

.create-operation-header {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 100%;
}

.create-operation-title {
    font-weight: 700;
    font-size: 1.8rem;
    letter-spacing: 0.1rem;
    text-align: center;
    margin-bottom: 20px;
    color: var(--primary-font-color);
    font-family: var(--font-primary);
    text-transform: uppercase;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.create-operation-title-underline {
    position: absolute;
    bottom: 40%;
    height: 2px;
    background-color: var(--secondary-background);
    width: 80%;
    z-index: 0;
}

.creation-container .operation-card {
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 15px;
    margin: 10px 0;
    transition: transform 0.2s;
    min-height: 200px;
    min-width: 300px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.creation-container .operation-card p {
    font: var(--font-primary);
    font-size: 1.2rem;
}

.creation-container .operation-card .operation-name {
    font-weight: bold;
    text-align: center;
    font-size: 1.3rem;
}

.creation-container .operation-card .operation-buttons {
    display: flex;
    gap: 10px;
}

.creation-container .operation-card button {
    font: var(--font-primary);
    font-size: 1rem;
    background-color: blue;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 5px;
    cursor: pointer;
    max-width: 100px;
}

.creation-container .operation-card:hover {
    transform: scale(1.005);
}

.creation-container ul {
    list-style-type: none;
    padding: 0;
}

.create-operation-popup-container {
    position: absolute;
    top: 0;
    left: 15vw;
    width: 85vw;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.create-operation-button {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 1000;
    background-color: blue;
    color: white;
    border: none;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    font-size: 20px;
    cursor: pointer;
    transition: transform 0.2s ease-in-out, background-color 0.2s ease-in-out;
}

.create-operation-button.close {
    transform: rotate(45deg);
    background-color: red;
}

.operation-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 10px;
}

.delete-operation-confirmation-popup {
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 15px;
    margin: 10px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    min-width: 300px;
    min-height: 200px;
}

.delete-operation-confirmation-buttons {
    display: flex;
    gap: 10px;
}

.delete-operation-confirmation-popup p {
    font: var(--font-accent);
    font-size: 1.4rem;
}

.delete-operation-confirmation-popup .delete-operation-confirmation-buttons button {
    padding: 20px;
    background-color: var(--secondary-background);
    color: var(--primary-font-color);
    border: none;
    border-radius: 15px;
    cursor: pointer;
    font-size: 1.2rem;
    min-width: 150px;
    font-family: var(--font-primary);
    font-weight: 600;
    letter-spacing: 0.05rem;
    box-shadow: 2px 3px 12px 2px #0000001a;
    text-transform: uppercase;
}