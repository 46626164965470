.creation-container {
    width: 100%;
    margin: 0 auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    overflow-y: auto;
    height: 100%;
    font-family: var(--font-primary);
}

.create-partenaire-header {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 100%;
}

.create-partenaire-title {
    font-weight: 700;
    font-size: 1.8rem;
    letter-spacing: 0.1rem;
    text-align: center;
    margin-bottom: 20px;
    color: var(--primary-font-color);
    font-family: var(--font-primary);
    text-transform: uppercase;
}

.create-partenaire-popup-container {
    position: absolute;
    top: 0;
    left: 15vw;
    width: 85vw;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}
