.day {
  width: 19%;
  height: 100%;
  overflow: visible;
}

.day h3 {
  margin: 0;
  user-select: all;
  cursor: default;
  font-size: 1.2rem;
  text-align: center;
}

.time-slots {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.time-slot {
  border: 1px solid #ddd;
  cursor: pointer;
  position: relative;
  width: 100%;
  height: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow: visible;
  box-sizing: border-box;
}

.time-slot.occupied {
  background-color: lightgray;
  cursor: not-allowed;
}

.time-slot.hovered {
  background-color: lightgray;
  cursor: pointer;
}

.time-slot.hour-separator {
  border-top: 1px solid #000000;
}

.time-slot.last-hour-separator {
  border-bottom: 1px solid #000000;
}

.delete-rdv-button {
  position: absolute;
  top: 25%;
  right: 0;
  height: 50%;
  max-width: 50%;
}

.time-label {
  margin-right: 10px;
  font-weight: bold;
}

