.details-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.details-popup-content {
  width: 50%;
  height: 50%;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.details-popup-content header {
  width: 100%;
}

.details-popup-content header h2 {
  font-size: 2rem;
  font-family: var(--font-accent);
  font-weight: 600;
  text-align: center;
}

.details-popup-content-inputs {
  width: 100%;
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  justify-content: center;
  min-height: 50%;
  gap: 1.5rem;
}

.details-popup-content-inputs-input {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 20px;
  align-items: center;
  min-height: 3rem;
}

.details-popup-content-inputs-input label {
  font-family: var(--font-primary);
  font-weight: 600;
  text-align: left;
  padding-left: 5px;
  margin-bottom: 10px;
}

.details-popup-content-inputs-input input {
  border: 1px solid var(--primary-color);
  border-radius: 5px;
  padding: 5px;
  transition: border 0.3s ease, background-color 0.3s ease;
  background-color: transparent;
  width: 100%;
  min-height: 2rem;

}

.details-popup-content-inputs-input input:focus {
  border: 1px solid var(--secondary-color);
  background-color: rgba(0, 0, 0, 0.068);
}

.details-popup-content-buttons {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 40px;
}

.details-popup-content button {
  margin-bottom: 20px;
  background-color: var(--primary-color);
  border: none;
  border-radius: 10px;
  padding: 10px;
  font-family: var(--font-accent);
  font-weight: 600;
  cursor: pointer;
}