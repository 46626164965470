.header-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 1rem;
  margin-bottom: 2rem;
}

.header-container h1 {
  font-size: 3rem;
  color: black;
  text-align: center;
}

.header-container h2 {
  font-size: 2rem;
  color: #303030;
  text-align: center;
}

@media (max-width: 1200px) and (orientation: portrait) {
  .header-container h1 {
    font-size: 1.5rem;
  }

  .header-container h2 {
    font-size: 1.2rem;
  }
}

@media (max-width: 1200px) and (orientation: landscape) {
  .header-container h1 {
    font-size: 1.1rem;
  }

  .header-container h2 {
    font-size: 0.8rem;
  }
}