.client-request-popup {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  background-color: var(--primary-background);
  border-radius: 10px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  width: 80%;
  max-width: 600px;
  margin: 20px auto;
}

.client-request-popup h2 {
  font-size: 1.8rem;
  color: var(--primary-font-color);

  margin-bottom: 20px;
}

.appointment-list {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
}

.appointment-button {
  padding: 10px;
  background-color: var(--secondary-background);
  color: var(--primary-font-color);

  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  text-align: left;
  width: 100%;
}


.close-button {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: var(--secondary-background);
  color: var(--primary-font-color);
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.close-button:hover {
  background-color: #c82333;
}