.delete-rdv-button {
  background-color: transparent;
  border: none;
  cursor: default;
  position: absolute;
  top: 20%;
  right: 10%;
  height: 100%;
  overflow: visible;
}

.delete-rdv-button svg {
  height: 100%;
}

.delete-rdv-button svg path {
  fill: #000000;
  transition: fill 0.3s ease, transform 0.3s ease;
}

.delete-rdv-button:hover svg path {
  transform: scale(1.1);
  fill: #ff0000;
}