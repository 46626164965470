.loading-text {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	font-size: 2rem;
	/*
	text-wrap: nowrap;
	*/
}

body {
	overflow: hidden;
	margin: 0;
	padding: 0;
	background-color: whitesmoke;
}

.user-container {
	margin: 20px;
	border-radius: 12px;
	background-color: #D2D2D2;
	transition: 0.3s ease-in;
	min-height: calc(100vh - 40px);
	min-width: calc(100vw - 40px);
}

@media (max-width: 1200px) and (orientation: portrait) {

}

@media (max-width: 1200px) {
	.loading-text {
		font-size: 1.7rem;
		text-wrap: wrap;
		min-width: 80%;
	}
}
