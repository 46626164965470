.dropdown-options-container {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  right: 0.5rem;
  top: 0.5rem;
  min-height: 1rem;
  background-color: var(--secondary-background);
}

div .dropdown-options-container .dropdown-dots {
  background: none;
  border: none;
  cursor: pointer;
  padding: 5px;
  display: flex;
  gap: 3px;
  border-radius: 5px;
  transition: background-color 0.3s ease;
  min-width: 1.5rem;
  justify-content: space-around;
  align-items: center;
}

.dropdown-dots:hover {
  background-color: var(--primary-background);
}

.dot {
  width: 4px;
  height: 4px;
  background-color: var(--tertiary-font-color);
  border-radius: 50%;
}

.options-menu {
  position: absolute;
  right: 0;
  top: 100%;
  background-color: var(--primary-background);
  border-radius: 5px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  max-height: 0;
  opacity: 0;
  z-index: 1000;
  min-width: 150px;
}

div .options-menu .option-item {
  width: 100%;
  padding: 10px 15px;
  text-align: left;
  background: none;
  border: none;
  cursor: pointer;
  color: var(--primary-font-color);
  font-family: var(--font-primary);
  font-size: 0.7rem;
  background-color: var(--primary-background);

  transition: background-color 0.3s ease;
}

.options-menu .option-item:hover {
  background-color: var(--secondary-background);
}
