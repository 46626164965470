.modify-agent-form-container {
  margin-top: 20px;
  padding: 20px;
  background-color: var(--primary-background);
  border-radius: 15px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  width: 80%;
  min-width: 500px;
  height: 60%;
  max-height: 800px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

.form-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
}

.modify-agent-title {
  text-align: center;
  color: var(--primary-font-color);
  font-family: var(--font-primary);
  font-size: 1.8rem;
  letter-spacing: 0.05rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  text-transform: uppercase;
}

.modify-agent-form {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 100%;
  min-height: 70%;
}

.form-group {
  margin-bottom: 15px;
  gap: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  width: 100%;
}
