.change-time {
  display: flex;
  gap: 15px;
  width: 100%;
  align-items: center;
  border-top: 1px solid var(--secondary-background);
  padding-top: 10px;
  padding-bottom: 10px;
  overflow: hidden;
}

.change-time-button {
  display: flex;
  gap: 10px;
  margin-left: 10px
}

.change-time button {
  border: none;
  background-color: transparent;
  width: 30px;
  background-color: var(--button-color);
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.change-time .symbol {
  font-size: 1.7rem;
  font-weight: 500;
  font-family: var(--font-primary);
  color: var(--primary-font-color);
}

.change-time span {
  font-size: 1.5rem;
  font-weight: 400;
  font-family: var(--font-primary);
  color: var(--primary-font-color);
  letter-spacing: 0.03rem;
}

.change-time .week-separator {
  font-size: 1.5rem;
  font-weight: 400;
  font-family: var(--font-primary);
  color: var(--primary-font-color);
  letter-spacing: 0.03rem;
}

.change-time .timezone {
  font-size: 1rem;
  font-weight: 600;
  font-family: var(--font-primary);
  color: var(--button-symbol-color);
  letter-spacing: 0.03rem;
}