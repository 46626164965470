.agent-schedule {
  display: flex;
  flex-direction: column;
  height: 90%;
  width: 100%;

  * {
    user-select : none;
    overflow-y: auto;
  }

  z-index: 1;

}

.agent-calendar {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-bottom: 2rem;
}

.days-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: fit-content;
  height: 100%;
  padding-right: 1rem;
  overflow: visible;
  box-sizing: border-box;
}

.days-header {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.day-header {
  display: flex;
  flex-direction: column;
  width: calc((100% - 5%) / 5);
  border: 1px solid #ddd;
  justify-content: space-around;
  align-items: center;
  text-align: center;
  padding: 0.3rem;
  overflow: visible;
}

.day-header h3 {
  margin: 0;
  font-size: 1.2rem;
  font-family: var(--font-primary);
  color: var(--font-color-primary);
  overflow: visible;
}

.day-header h4 {
  margin: 0;
  font-size: 1rem;
  font-weight: 500;
  font-family: var(--font-primary);
  color: var(--tertiary-font-color);
  text-transform: capitalize;
  overflow: visible;

}

.empty-day {
  width: 5%;
}

.days-content {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: calc(100% -  3rem);
}

.hour-container {
  display: flex;
  flex-direction: column;
  width: 5%;
  height: 100%;
  justify-content: space-between;
  overflow: visible;
}

.hour {
  border: 1px solid #ddd;
  display: flex;
  height: calc(100% / 36);
  align-items: center;
  justify-content: center;
  overflow: visible;

}

.calendar-header-container {
  height: 10%;
}