.role-attribution-container {
  position: fixed;
  top: 50%;
  left: 51%;
  transform: translate(-50%, -50%);
  margin: 0;
  padding: 20px;
  background-color: var(--primary-background);
  border-radius: 15px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  width: 80%;
  min-width: 500px;
  max-width: 800px;
  height: auto;
  min-height: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  z-index: 1000;
}

.role-attribution-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.349);
  z-index: 999;
}

.roles-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 20px;
  width: 100%;
  padding: 20px;
  margin: 20px 0;
}

.role-item {
  background-color: var(--secondary-background);
  padding: 15px;
  border-radius: 10px;
  transition: transform 0.2s ease;
}

.role-item:hover {
  transform: translateY(-2px);
}

.role-checkbox {
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  font-family: var(--font-primary);
  font-size: 1.1rem;
}

.role-checkbox input[type="checkbox"] {
  width: 18px;
  height: 18px;
  cursor: pointer;
}

.role-name {
  color: var(--primary-font-color);
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.05rem;
  user-select: none;
}

.role-attribution-button {
  padding: 0 !important;
}