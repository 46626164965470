.calendar-header {
	width: 100%;
  min-height: 100px;
  height: 100%;
  display: flex;
  justify-content: center;
  padding-top: 1rem;
}

.calendar-header-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: fit-content;
}

.calendar-header h1 {
  font-size: 2rem;
  font-family: var(--font-accent);
  font-weight: 600;
}

.calendar-header-underline {
  width: 120%;
  margin-top: 0.5rem;
  height: 2px;
  background-color: var(--secondary-background);
}