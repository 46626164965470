body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

:root {
    interpolate-size: allow-keywords;
    background-color: var(--primary-background);
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  --primary-color: #dbdbdb;
  --secondary-color: #cfcfcf;
  --font-primary: "DM Sans", sans-serif;
  --font-accent: "Roboto", sans-serif;

  --primary-background: #f5f5f5;
  --secondary-background: #d9d9d9;
  --tertiary-background: white;
  
  --accent-background: #000000;
  --primary-font-color: #1f1f1f;
  --secondary-font-color: #2b2b2b;
  --tertiary-font-color: #727272;

  --button-color: #dce3ff;
  --button-hover-color: #b6c7ff;
  --button-symbol-color: #3a5c80;

  --secondary-button-color: #007bff;
  --secondary-button-hover-color: #0056b3;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
