.info-card {
    --shadow-spread: 4px;
    background-color: var(--primary-background);
    border-radius: 5px;
    padding: 15px;
    margin: 10px 0;
    transition: transform 0.2s;
    min-height: 300px;
    min-width: 40px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    box-shadow: 4px 4px 5px var(--shadow-spread) #0000001a;
}

.info-card-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 10px;
}

.info-card p {
    font: var(--font-primary);
    text-align: center;
    max-width: 80%;
}

.info-card .info-card-name {
    text-align: center;
    font-size: 1.4rem;
    font-weight: 700;
    text-transform: uppercase;
    color: var(--primary-font-color);
}

.info-card .info-card-name-underline {
    width: calc(100% - 20px);
    height: 1px;
    background-color: var(--secondary-background);
    margin-left: 10px;
    margin-top: 5px;
}

.info-card .info-card-buttons {
    display: flex;
    width: 100%;
    justify-content: space-around;
    gap: 10px;
}

.info-card button {
    --shadow-spread: 0px;
    --shadow-blur: 0px;
    --shadow-color: #000000;
    font-family: var(--font-primary);
    font-size: 1rem;
    background-color: var(--secondary-background);
    color: var(--primary-font-color);
    border: none;
    border-radius: 5px;
    padding: 5px;
    cursor: pointer;
    width: 100px;
    box-shadow: 0 0 var(--shadow-blur) var(--shadow-spread) var(--shadow-color);
}

.info-card-label {
    font-weight: 700;
    font-size: 1.5rem;
    letter-spacing: 0.05rem;
    text-transform: uppercase;
}

.info-card-value {
    font-size: 1.4rem;
    font-weight: 500;
    text-transform: uppercase;
}