.role-form-container {
  margin-top: 20px;
  padding: 20px;
  background-color: var(--primary-background);
  border-radius: 15px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  width: 50% !important;
  min-width: 500px;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

.form-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
}

.form-group {
  margin-bottom: 15px;
  gap: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  width: 100%;
}

.form-group label {
  margin-bottom: 5px;
  font-weight: bold;
  font-family: var(--font-primary);
  font-size: 1.2rem;
}

.form-group input {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  width: 100%;
}

button[type="submit"] {
  padding: 20px;
  background-color: var(--secondary-background);
  color: var(--primary-font-color);
  border: none;
  border-radius: 15px;
  cursor: pointer;
  font-size: 1.2rem;
  min-width: 150px;
  font-family: var(--font-primary);
  font-weight: 600;
  letter-spacing: 0.05rem;
  box-shadow: 2px 3px 12px 2px #0000001a;
  text-transform: uppercase;
}
