.delete-popup {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 50%;
  max-height: 50%;
  padding: 20px;
  border-radius: 10px;
  background-color: #d4d4d4;
}
.delete-popup-buttons {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.delete-popup-button {
  margin: 10px;
  padding: 10px;
  border-radius: 5px;
  background-color: #007bff;
  color: white;
  cursor: pointer;
}