.new-data-container .form-content .new-data-form {
  display: flex;
  flex-direction: column;
  gap: 10px;
}


.new-data-container .new-data-form {
  margin-bottom: 1rem;
}

.new-data-container .new-data-form .new-data-form-input {
  background-color: var(--button-color);
  transition: 0.3s;
}

.new-data-container .new-data-form .new-data-input:hover {
  background-color: var(--button-hover-color);
}

.new-data-container .new-data-form .new-data-button {
  background-color: var(--button-color);
  transition: all 0.3s;
}

.new-data-container .new-data-form .new-data-button:hover {
  background-color: var(--button-hover-color);
}