.login-page {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: var(--tertiary-background);
  font-family: var(--font-primary);

}

.underline{
  width: 80%;
  height: 1px;
  background-color: var(--primary-font-color);
}

.login-container {
  background-color: var(--primary-background);
  padding: 40px;
  border-radius: 8px;
  width: 100%;
  max-width: 650px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);

}

.login-container .login-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 30px;

}

.login-container .login-header h1 {
  text-align: center;
  color: var(--primary-font-color);
  font-family: var(--font-accent);
  font-size: 30px;
  font-weight: 500;
  letter-spacing: 1.3px;
}

.login-container .login-header .underline{
  width: 80%;
  margin: 0 auto;
  margin-top: 3px;
  color: var(--tertiary-font-color);
  height: 1px;
}

.login-container .login-form .form-group {
  margin-top: 15px;
  margin-bottom: 15px;
}

.login-container .login-form .form-group label {
  display: block;
  font-size: 20px;
  color: var(--primary-font-color);
  margin-bottom: 8px;
  font-weight: 500;
}

.form-group input {
  width: 100%;
  padding: 12px;
  border: 1px solid #e0e0e0;
  color: var(--primary-font-color);
  border-radius: 4px;
  background-color: #f8f9fa;
  font-size: 14px;
}

.form-group input:focus {
  outline: none;
  border-color: #007bff;
  background-color: white;
}

.button-group {
  display: flex;
  gap: 15px;
  margin-top: 30px;
  justify-content: center;
  align-items: center;
}

.button-group button {
  flex: 0.5;
  padding: 12px 20px;
  border: none;
  border-radius: 4px;
  font-size: 13px;
  cursor: pointer;
  font-weight: 500;
  text-transform: uppercase;
}

.login-button {
  background-color: var(--secondary-button-color);
  color: white;
  text-transform: uppercase;
  transition: all 0.3s ease-in-out;
}

.login-button:hover {
  background-color: var(--secondary-button-hover-color);
}

.forgot-password {
  background-color: #007bff;
  color: white;
}

.forgot-password:hover {
  background-color: #0056b3;
}

.error-message {
  color: #dc3545;
  text-align: center;
  margin-bottom: 20px;
  font-size: 14px;
}

